export const environment = {
    production: false,
    apiUrl: 'https://api-cnbvra-app-back.bomberosra.org:443/api',
    azure: {
        auth: {
            clientId: "ef0b461e-564a-4d30-a311-1223c37b373b",
            tenantId: "dff16913-7f1d-47de-a9f5-e4a42edd9db7",
            redirectUri: "https://appstest.bomberosra.org",
            postLogoutRedirectUri: 'https://appstest.bomberosra.org',
        },
        apiScope: 'api://d27a8041-d4a9-4a26-b952-690e255f273c/api.scope'
    },
};